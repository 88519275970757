<template>
  <div class="vlt-share" v-if="showPopup">
    <div class="vlt-share-contain">
      <div class="share-popup">
        <div class="share-box">
          <ul>
            <li @click="shareFbCB">
              <ShareFacebook class="share-ico" alt="fb icon" :isClicked="isShareFb" />
              <!-- <img src="@/assets/icons/share-facebook.svg" class="share-ico" alt="fb icon" /> -->
            </li>
            <li>
              <a target="_blank" :href="twitterShareUrl" id="twitter-button" @click="shareTwCB()">
                <ShareTwitter class="share-ico" alt="tw icon" :isClicked="isShareTw" />

                <!-- <img src="@/assets/icons/share-twitter.svg" class="share-ico" alt="gp icon" /> -->
              </a>
            </li>
            <li>
              <a
                class="custom-link"
                target="_blank"
                @click="shareCount('OTHERS');copyText();"
              >
                <ShareDeeplink class="share-ico" alt="dl icon" :isClicked="isShareDl" />

                <!-- <img src="@/assets/icons/share-deep-link.svg" class="share-ico" alt="tw icon" /> -->
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div
      class="share-overlay"
      @click="() => togglePopup(false)"
    ></div>-->
  </div>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import snackbar from "node-snackbar";
import "nm/node-snackbar/dist/snackbar.css";

export default {
  name: "Share",
  props: ["content", "showPopup", "displayButton", "buttonDisplayDiv"],
  data() {
    return {
      twitterShareUrl: undefined,
      gplusShareUrl: undefined,
      link: null,
      imgUrl: require("@/assets/icons/share-facebook.svg"),
      isShareFb: false,
      isShareTw: false,
      isShareDl: false
    };
  },
  created() {
    this.initShare();
    if (this.color === "BLACK") {
      this.imgUrl = require("@/assets/icons/share-facebook.svg");
    }
  },
  watch: {
    content() {
      this.isShareFb = false;
      this.isShareTw = false;
      this.isShareDl = false;
    }
  },
  methods: {
    shareCount(medium) {
      // this.vLiveSDKContentShare(this.content, medium);
      this.isShareDl = true;
      this.isShareTw = false;
      this.isShareFb = false;
    },
    copyText(){
      let clipboard=navigator.clipboard;
      console.log(clipboard);
      if(!clipboard)return;
      let link = `${window.location.origin}/detail-card/${this.content.contentid}`;
      clipboard.writeText(link).then(()=>{
         snackbar.show({ text: "Link copied", pos: "bottom-center" });
      }).catch(err=>{
         console.log(err); 
      })
    },
    initShare() {

      if (!this.content) return;
      // Creating the twitter share URL
      this.twitterShareUrl =
        "https://twitter.com/share?url=" +
        encodeURIComponent(`${window.location.origin}/detail-card/${this.content.contentid}`);
      // Creating the Google Plus share URL
      this.gplusShareUrl = `https://plus.google.com/share?url=${encodeURIComponent(
        `${window.location.origin}/detail-card/${this.content.contentid}`
      )}`;
    },
    togglePopup(state) {
      this.showPopup = state;
    },
    getPoster(content) {
      // return content.poster;

      if (content.posters.length) {
        let landscapeposters = content.posters.filter(
          pos => pos.orientation === "PORTRAIT"
        );
        if (landscapeposters.length) {
          let posterIndex = landscapeposters.findIndex(
            pos => pos.quality === "SD"
          );
          if (posterIndex > -1) {
            return landscapeposters[posterIndex].url;
          } else {
            return landscapeposters[0].url;
          }
        }
      }
    },
    shareFbCB() {
      this.isShareFb = true;
      this.isShareTw = false;
      this.isShareDl = false;
      // this.vLiveSDKContentShare(this.content, "FACEBOOK");
      // Get the URL to be shared
      let link = `${window.location.origin}/detail-card/${this.content.contentid}`;
      // Get the image to be shown on the feed
      let image = this.getPoster(this.content);
      // Get the title to be shown on the feed
      let title = `Watch ${this.content.title} on Vlite`;
      // Get the description to be shown on the feed
      let description = this.content.description;
      title = "abcd";
      console.log(" fb details ", link, image, title, description);
      // Initialize Share
      FB.ui(
        {
          method: "share_open_graph",
          action_type: "og.shares",
          action_properties: JSON.stringify({
            object: {
              "og:url": link,
              "og:title": title,
              "og:description": description,
              "og:image": image
            }
          })
        },
        response => {
          // console.log('[shareFbCB response]', response);
        }
      );
    },
    shareGpCB() {
      // console.log('[shareGpCB]');
    },
    shareTwCB() {
      this.isShareTw = true;
      this.isShareFb = false;
      this.isShareDl = false;

      // this.vLiveSDKContentShare(this.content, "TWITTER");
      let image = this.getPoster(this.content);
      //        <meta name="twitter:card" content="summary" />
      // <meta name="twitter:site" content="@flickr" />
      // <meta name="twitter:title" content="Small Island Developing States Photo Submission" />
      // <meta name="twitter:description" content="View the album on Flickr." />
      // <meta name="twitter:image" content="https://farm6.staticflickr.com/5510/14338202952_93595258ff_z.jpg" />
      let linkTag = document.getElementsByTagName("link")[0];
      let summary = "";
      summary = document.createElement("meta");
      summary.name = "twitter:card";
      summary.content = "summary";
      document.head.insertBefore(summary, linkTag);

      let twttrSite = "";
      twttrSite = document.createElement("meta");
      twttrSite.name = "twitter:site";
      twttrSite.content = "@vLite";
      document.head.insertBefore(twttrSite, linkTag);

      let ogUrl = "";
      ogUrl = document.createElement("meta");
      ogUrl.setAttribute("property", "og:url");
      ogUrl.content =
        "http://bits.blogs.nytimes.com/2011/12/08/a-twitter-for-my-sister/";
      document.head.insertBefore(ogUrl, linkTag);

      let twttrTitle = "";
      twttrTitle = document.createElement("meta");
      twttrTitle.setAttribute("property", "og:title");
      twttrTitle.content = this.content.title;
      document.head.insertBefore(twttrTitle, linkTag);

      let twttrImg = "";
      twttrImg = document.createElement("meta");
      twttrImg.setAttribute("property", "og:image");
      twttrImg.content = image;
      document.head.insertBefore(twttrImg, linkTag);
    }
  },
  components: {
    ShareFacebook: () =>
      import(
        /* webpackChunkName: "facebook-svg" */ "@/components/svgComponents/shareFacebook.vue"
      ),
    ShareTwitter: () =>
      import(
        /* webpackChunkName: "twitter-svg" */ "@/components/svgComponents/shareTwitter.vue"
      ),
    ShareDeeplink: () =>
      import(
        /* webpackChunkName: "facebook-svg" */ "@/components/svgComponents/shareDeeplink.vue"
      )
  },
  mixins: [appMixins]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";

 .share-ico {
    width:calc(var(--icon-width) - 1vw);
  }
.vlt-share {
  left: -210px;

  -webkit-animation: slide 2s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 2s forwards;
  animation-delay: 2s;

  @-webkit-keyframes slide {
    100% {
      left: 0;
    }
  }

  @keyframes slide {
    100% {
      left: 0;
    }
  }

  // Top element styles are defined where the component is being declared
  .vlt-share-contain {
    position: relative;
    height:100%;
    // z-index: 89;
    .share-popup {
      position: absolute;
      padding:0 10px;
      width: 210px;
      border-radius: 40px;
      top: 50%;
      transform:translateY(-50%);
      left: 100%;
      transition: all 0.5s;
      // right: calc(100% - 26px);
      // top: 130%;
      // box-shadow: 0px 1px 6px 0px #d0d0d0;
      ul {
        // text-align: center;
        display: flex;
        flex-flow: row nowrap;
        li {
          display: block;
          margin-right: 15px;
          cursor: pointer;

          img {
            width: 40px;
            padding: 8px;
            vertical-align: middle;
            // background-color: #b9b9b9;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .vlt-btn-ico {
      cursor: pointer;
    }
    //.share-box {
      //margin-top: 5px;
    //}
  }
  .share-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: red;
    z-index: 88;
  }
}
// @media only screen and (max-width: 576px) {
@include breakpoint(max576) {
  .share-ico {
    width:calc(var(--icon-width) - 2vw);
  }
  .vlt-share-contain {
    .share-popup {
      border-radius: 20px;
      padding: 0px;
      transition: all 0.5s;
      ul {
        display:flex;
        flex-flow:row nowrap;
        justify-content: flex-end;
      }
    }
  }
}
/* Extra small devices (phones, 600px and down) */
// @media only screen and (min-width: 576px) and (max-width: 768px) {
@include breakpoint(min600max768) {
  .vlt-share-contain {
    .share-popup {
      position: absolute;
      width: 150px;
      border-radius: 40px;
      top: 0px;
      left: 0px;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      ul {
        li {
          margin-right: 0px;
          display: block;
          img {
            width: 25px;
            padding: 5px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

// 4k
// @media screen and (min-width: 1900px) {
@include breakpoint(desktopLarge) {
  .share-icon {
    padding-top: 0;
    img {
      width: 35px;
    }
  }
}
</style>